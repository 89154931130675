import {
    eventHandlers,
    setImagesSource,
    setNavigationHtml,
    sendWarning,
} from "./handlers";
import environment from "./environment";
import {
    getDeviceType,
    getBrowserSize,
    MOBILE,
    SMALL,
    fetchData,
} from "./utils";
import { processTemplateData, addProductFields } from "./funcs";
import "./index-old";
import { getExtraParams } from "./recommendationRequests";
import { sliderConfig } from "./utils";
import { incrementCounter } from "./incrementCounter";
import { generateRexContent } from "./generateRexContent";
window.generateRexContent = generateRexContent;
global._unbxd_recsSliderScrollNext = eventHandlers._unbxd_recsSliderScrollNext;
global._unbxd_recsSliderScrollPrev = eventHandlers._unbxd_recsSliderScrollPrev;
global._unbxd_recsSliderSideScroll = eventHandlers._unbxd_recsSliderSideScroll;
global.eventQueue = {};
global._unbxd_registerHook = function (eventName, eventCallback) {
    global.eventQueue[eventName] = eventCallback;
};
global._unbxd_recsSliderScrollBottom =
    eventHandlers._unbxd_recsSliderScrollBottom;
global._unbxd_recsSliderScrollTop = eventHandlers._unbxd_recsSliderScrollTop;
global._unbxd_recsSliderVerticalScroll =
    eventHandlers._unbxd_recsSliderVerticalScroll;

window.objIsEmpty = function (o) {
    for (const k in o) {
        // eslint-disable-next-line no-prototype-builtins
        if (o.hasOwnProperty(k)) {
            return false;
        }
    }
    return true;
};
let isResized;
window.getUnbxdRecommendations = (context) => {
    //templatesInit(context);
    // getting template Info
    try {
        //setting context in window
        window.context = context;
        addEventListenersForResizeAndOrientation(window.context);
        window.unbxdDeviceType = getDeviceDetails(context);
        // getting widget id
        var widgets = context.widgets;
        if (widgets && widgets.length > 0) {
            window.context = context;
            window.widgetsConfig = context.widgets;
        } else {
            //logging functionality if needed
            throw new Error("No widget id provided");
        }
        let HOME_PAGE = "home";
        let PRODUCT_PAGE = "product";
        let CATEGORY_PAGE = "category";
        let CART_PAGE = "cart";
        let BOUTIQUE_PAGE = "boutique";
        let allowedPageTypes = [
            HOME_PAGE,
            PRODUCT_PAGE,
            CATEGORY_PAGE,
            CART_PAGE,
            BOUTIQUE_PAGE,
        ];
        window.allowedPageTypes = allowedPageTypes;
        let pageType = getPageDetails(context.pageInfo);

        window.unbxdPageType = pageType;
        window.itemClickHandler = getClickHandler(context);
        window.dataParser = getDataParserHandler(context);
        window.eventQueue = global.eventQueue;
        fetchRecommendations(context);
    } catch (error) {
        console.log(error, "error in parsing context object");
    }
};
const fetchRecommendations = (context) => {
    try {
        let userId =
            (context.userInfo && context.userInfo.userId) ||
            this.getCookie("unbxd.userId");
        let siteKey =
            (context.userInfo && context.userInfo.siteKey) ||
            window.UnbxdSiteName;
        let apiKey =
            (context.userInfo && context.userInfo.apiKey) || window.UnbxdApiKey;
        let platformDomain = environment[process.env.NODE_ENV].url;
        let requestUrl =
            platformDomain +
            apiKey +
            "/" +
            siteKey +
            "/recommendation?&template=true&pageType=";
        if (!userId) {
            throw new Error("user id is missing");
        }

        if (!siteKey) {
            throw new Error("site Key is missing");
        }

        if (!apiKey) {
            throw new Error("api key is missing");
        }

        requestUrl += encodeURIComponent(window.unbxdPageType);
        let params = getExtraParams(requestUrl);
        if (params) {
            requestUrl += params;
        }
        // if (context.pageInfo && context.pageInfo.boutiqueButton) {
        //     requestUrl += "&boutiqueinfo=true";
        // }
        requestUrl += "&uid=" + userId;
        if (
            context &&
            context.extraParams &&
            typeof context.extraParams === "object" &&
            Object.keys(context.extraParams).length > 0
        ) {
            let extraParamsRequest = "";
            const extraParamKeys = Object.keys(context.extraParams);
            extraParamKeys.forEach((param, index) => {
                if (index > 0) {
                    extraParamsRequest += "&";
                }
                extraParamsRequest += `${param}=${encodeURIComponent(
                    context.extraParams[param]
                )}`;
            });
            requestUrl += `&${extraParamsRequest}`;
        }
        fetchData(requestUrl, true, ParseTemplateData);
    } catch (e) {
        console.log("error at fetching recommendations", e);
    }
};
function ParseTemplateData(err, res, requestId) {
    // fetching data specific to a page type
    try {
        if (err) {
            console.error(err, "error in widget data");
            throw new Error("Failed to fetch recommendations", err);
        }
        let recommendationsResponse = JSON.parse(res);
        let reqId = requestId;
        window.reqId = reqId;
        if (
            recommendationsResponse &&
            recommendationsResponse.response &&
            recommendationsResponse.response.globalTemplateData
        ) {
            if (
                recommendationsResponse.response.globalTemplateData.addOnAssets
            ) {
                window.globalAddonsAssets =
                    recommendationsResponse.response.globalTemplateData.addOnAssets;
            }
            if (
                recommendationsResponse.response.globalTemplateData &&
                recommendationsResponse.response.globalTemplateData.boutique &&
                recommendationsResponse.response.globalTemplateData
                    .boutique[0] &&
                Object.keys(
                    recommendationsResponse.response.globalTemplateData
                        .boutique[0]
                ).length > 0
            ) {
                setBoutiqueInfo(
                    recommendationsResponse.response.globalTemplateData
                        .boutique[0]
                );
            }
        }
        if (
            recommendationsResponse &&
            recommendationsResponse.response &&
            recommendationsResponse.response.widgets &&
            recommendationsResponse.response.widgets.length > 0
        ) {
            window.tc = {};
            let templates = recommendationsResponse.response.widgets;

            var pagesPromise = Promise.all(
                templates.map(function (template) {
                    if (template.count) {
                        return processTemplateData(template);
                    }
                })
            );
            pagesPromise.then(function () {
                for (const wI in window.tc) {
                    let template = window.tc[wI];
                    let configType =
                        template.conf.customProperties.widgetType === 3
                            ? template.conf.customProperties.basedOn
                                ? `${template.conf.templateType}_${template.conf.customProperties.basedOn}`
                                : template.conf.templateType
                            : template.orientation;
                    let sliderContent = sliderConfig[configType];
                    var domSelector =
                        "#" +
                        template.selector +
                        " " +
                        sliderContent.containerId;
                    var sliderContainer = document.querySelector(domSelector);
                    let sliderClass =
                        template.orientation == "vertical" ||
                        !window.unbxdDeviceType === "mobile-browser"
                            ? "_unbxd_recs-vertical-slider"
                            : "_unbxd_recs-slider";
                    let sliderItemSelector =
                        "#" +
                        template.selector +
                        " ." +
                        sliderContent.sliderItemClassSelector;
                    let sliderItems =
                        document.querySelectorAll(sliderItemSelector);
                    if (!sliderItems.length) {
                        sendWarning(
                            "Found 0 nodes with class : " +
                                sliderContent.sliderItemClassSelector
                        );
                    }
                    var productFields = template.conf.products.fields;
                    productFields = productFields.sort(function (b, a) {
                        a.sequence = a.sequence || a.sequence_number;
                        b.sequence = b.sequence || b.sequence_number;
                        if (a.sequence < b.sequence) {
                            return 1;
                        }
                        return -1;
                    });
                    addProductFields(
                        template,
                        sliderItems,
                        productFields,
                        template.recommendations,
                        sliderContent,
                        domSelector,
                        sliderClass,
                        template.maxProducts,
                        domSelector
                    );
                    var maxprodLimit = template.maxProducts;
                    var recsSliderSelector =
                        "#" + template.selector + " ." + sliderClass;
                    var recsSlider = document.querySelector(recsSliderSelector);
                    if (!recsSlider) {
                        sendWarning(
                            "Slider Parent id was not found in the DOM"
                        );
                    }
                    if (
                        template.recommendations.length < template.maxProducts
                    ) {
                        maxprodLimit = template.recommendations.length;
                    }
                    if (sliderContainer) {
                        addEventListener(
                            sliderContent,
                            sliderContainer,
                            template.recommendationsModified &&
                                template.recommendationsModified.length
                                ? template.recommendationsModified
                                : template.recommendations,
                            template
                        );
                        incrementCounter(
                            sliderContent,
                            template,
                            sliderContainer,
                            sliderItems,
                            sliderClass,
                            maxprodLimit
                        );

                        addCssAndHeadersForTemplates(template, sliderContent);
                    }
                }
            });
        }
    } catch (err) {
        console.log("Error in parsing recs template data", err);
    }
}
function getPageDetails(pageInfo) {
    if (!pageInfo || !pageInfo.pageType) {
        throw new Error("Page type info missing");
    }
    var pageTypeLocal = pageInfo.pageType;
    if (window.allowedPageTypes.indexOf(pageTypeLocal.toLowerCase()) == -1) {
        throw new Error("Invalid value for page type");
    }
    return pageTypeLocal;
}
function getDeviceDetails(context) {
    //revert dektop size browser here line 234,2
    var device = getDeviceType();
    var browserSize = getBrowserSize();
    if (context.unbxdDeviceType && context.unbxdDeviceType.mobileBrowser)
        return "mobile-browser";
    else if (context.unbxdDeviceType && context.unbxdDeviceType.desktopBrowser)
        return "desktop-browser";
    else if (device === MOBILE || browserSize === SMALL) {
        return "mobile-browser";
    } else {
        return "desktop-browser";
    }
}
function getClickHandler(context) {
    return context.itemClickHandler;
}

function getDataParserHandler(context) {
    return context.dataParser;
}

function handleResizeAndOrientationChange() {
    if (!isResized) {
        isResized = true;
        window.getUnbxdRecommendations(window.context);

        setTimeout(() => {
            isResized = false;
        }, 2000);
    }
}

function addEventListenersForResizeAndOrientation() {
    window.addEventListener("resize", handleResizeAndOrientationChange);

    // Add event listener for orientation change
    // window.addEventListener("orientationchange", handleResizeAndOrientationChange);
}
function addCssAndHeadersForTemplates(template, sliderContent) {
    if (template.recommendations.length <= template.itemsToShow) {
        var navigationButtonSelector =
            "#" + template.selector + " " + sliderContent.buttonClassSelector;
        var navigationButtons = document.querySelectorAll(
            navigationButtonSelector
        );
        if (!navigationButtons || !navigationButtons.length) {
            sendWarning(
                sliderContent.buttonClassSelector +
                    "class not found on navigation buttons"
            );
        }
        for (var i = 0; i < navigationButtons.length; i++) {
            navigationButtons[i].style.display = "none";
        }
    }

    // the previous button for the slider needs to be disabled initially
    var prevSliderButtonSelector =
        "#" + template.selector + " ." + sliderContent.prevButtonClass;
    var customNavButtonSelector =
        "#" + template.selector + " ." + sliderContent.customNavClass;
    var prevSliderButton = document.querySelector(prevSliderButtonSelector);
    var customNavButton = document.querySelector(customNavButtonSelector);

    var navCustomisable = false;

    if (customNavButton) {
        navCustomisable = true;
    } else {
        navCustomisable = false;
    }

    if (!prevSliderButton) {
        sendWarning(
            sliderContent.prevButtonClass +
                " class was not found on the navigation buttons"
        );
    }
    if (prevSliderButton) {
        prevSliderButton.disabled = true;
    }

    /** Setting images value */
    var imgArr = [];
    var classMap = {
        next_arrow: sliderContent.nextButtonClass,
        prev_arrow: sliderContent.prevButtonClass,
        empty_rating: "_unbxd_rex-empty-star",
        half_rating: "_unbxd_rex-half-star",
        full_rating: "_unbxd_rex-full-star",
    };
    var altTextMap = {
        next_arrow: "Next Arrow",
        prev_arrow: "Previous Arrow",
        empty_rating: "Empty star",
        half_rating: "half star",
        full_rating: "full star",
    };
    for (let i = 0; i < template.conf.assets.length; i++) {
        var horizontalAssetItem = template.conf.assets[i];
        imgArr.push({
            classname: classMap[horizontalAssetItem.tag],
            altText: altTextMap[horizontalAssetItem.tag],
            url: horizontalAssetItem.src,
        });
    }

    setNavigationHtml(imgArr, template.selector, navCustomisable);
    setImagesSource(imgArr, template.selector, navCustomisable);

    /** Setting images value end*/

    /** Setting styles for heading */
    var headingSelector =
        "#" + template.selector + sliderContent.headingContainerId;
    var styleConfig = template.conf.header;
    var headingEl = document.querySelector(headingSelector);
    if (headingEl) {
        if (
            headingEl.innerHTML == "null" ||
            headingEl.innerHTML == "undefined"
        ) {
            headingEl.style.display = "none";
        } else {
            headingEl.style.textAlign = styleConfig.alignment;
            headingEl.style.fontSize =
                styleConfig.text.size.value + styleConfig.text.size.unit;
            headingEl.style.fontWeight = styleConfig.text.style;
            headingEl.style.color = styleConfig.text.colour;
        }
    }
}
function addEventListener(
    sliderContent,
    sliderContainer,
    recommendations,
    template
) {
    if (window.itemClickHandler) {
        if (sliderContent.dimension == "width") {
            sliderContainer.addEventListener("click", function (event) {
                let classnameextra = "";
                const likedBlk = event.target.closest(
                    `.unbxd--like${classnameextra}`
                );
                const dislikedBlk = event.target.closest(
                    `.unbxd--dislike${classnameextra}`
                );
                const wishistlistedBlk = event.target.closest(
                    `.unbxd--wishlist${classnameextra}`
                );
                const sharedBlk = event.target.closest(
                    `.unbxd--share${classnameextra}`
                );
                if (event.target.closest(".unbxd--action--buttons")) {
                    if (likedBlk) {
                        likedBlk.classList.toggle("like-img");
                        likedBlk.classList.toggle("liked-img");
                        updateAddonIconValue(likedBlk, "like", "unlike");
                    }
                    if (dislikedBlk) {
                        dislikedBlk.classList.toggle("dislike-img");
                        dislikedBlk.classList.toggle("disliked-img");
                        updateAddonIconValue(
                            dislikedBlk,
                            "dislike",
                            "undislike"
                        );
                    }
                    if (wishistlistedBlk) {
                        wishistlistedBlk.classList.toggle("wishlisted-img");
                        wishistlistedBlk.classList.toggle("wishlist-img");
                        updateAddonIconValue(
                            wishistlistedBlk,
                            "favourite",
                            "unfavourite"
                        );
                    }
                    if (sharedBlk) {
                        sharedBlk.classList.toggle("shared-img");
                        sharedBlk.classList.toggle("share-img");
                    }
                    //logic for replacing when kill on like or dislike
                }
                if (
                    event.target.parentElement.className !==
                    "unbxd--action--buttons"
                ) {
                    if (
                        event.target.className ==
                        sliderContent.sliderItemClassSelector
                    ) {
                        handleHorizontalWidgetClicks(
                            event.target.id,
                            window.itemClickHandler,
                            recommendations,
                            sliderContent.RegexExp
                        );
                    } else {
                        var el = getClosestNode(
                            event.target,
                            `.${sliderContent.sliderItemClassSelector}`
                        );
                        handleHorizontalWidgetClicks(
                            el.id,
                            window.itemClickHandler,
                            recommendations,
                            sliderContent.RegexExp
                        );
                    }
                }
            });
        } else {
            sliderContainer.addEventListener("click", function (event) {
                let classnameextra = "";
                if (
                    template &&
                    template.conf &&
                    template.conf.orientation === "vertical"
                ) {
                    if (
                        template.conf.customProperties &&
                        template.conf.customProperties.widgetType === 2
                    ) {
                        classnameextra = "-eivertical";
                    }
                }
                const likedBlk = event.target.closest(
                    `.unbxd--like${classnameextra}`
                );
                const dislikedBlk = event.target.closest(
                    `.unbxd--dislike${classnameextra}`
                );
                const wishistlistedBlk = event.target.closest(
                    `.unbxd--wishlist${classnameextra}`
                );
                const sharedBlk = event.target.closest(
                    `.unbxd--share${classnameextra}`
                );
                if (event.target.closest(".unbxd--action--buttons")) {
                    if (likedBlk) {
                        likedBlk.classList.toggle("like-img");
                        likedBlk.classList.toggle("liked-img");
                        updateAddonIconValue(likedBlk, "like", "unlike");
                    }
                    if (dislikedBlk) {
                        dislikedBlk.classList.toggle("dislike-img");
                        dislikedBlk.classList.toggle("disliked-img");
                        updateAddonIconValue(
                            dislikedBlk,
                            "dislike",
                            "undislike"
                        );
                    }
                    if (wishistlistedBlk) {
                        wishistlistedBlk.classList.toggle("wishlisted-img");
                        wishistlistedBlk.classList.toggle("wishlist-img");
                        updateAddonIconValue(
                            wishistlistedBlk,
                            "favourite",
                            "unfavourite"
                        );
                    }
                    if (sharedBlk) {
                        sharedBlk.classList.toggle("shared-img");
                        sharedBlk.classList.toggle("share-img");
                    }
                    //logic for replacing when kill on like or dislike
                }
                if (
                    sliderContent.sliderItemClassSelector !==
                    "unbxd--action--buttons"
                ) {
                    if (
                        event.target.className ==
                        sliderContent.sliderItemClassSelector
                    ) {
                        let parentId =
                            event.target.parentElement.parentElement.id;
                        handleVerticalWidgetClicks(
                            event.target.id,
                            parentId,
                            window.itemClickHandler,
                            recommendations,
                            sliderContent.RegexExp
                        );
                    } else {
                        var el = getClosestNode(
                            event.target,
                            `.${sliderContent.sliderItemClassSelector}`
                        );
                        let parentId = el.parentElement.id;
                        handleVerticalWidgetClicks(
                            el.id,
                            parentId,
                            window.itemClickHandler,
                            recommendations,
                            sliderContent.RegexExp
                        );
                    }
                }
            });
        }
    }
}
function handleHorizontalWidgetClicks(
    parentId,
    clickHandler,
    recommendations,
    regExp
) {
    if (regExp.test(parentId)) {
        var arrayIndex = parentId.split("-")[2]; // fixed id of form hz-slider-0
        clickHandler(recommendations[arrayIndex]);
    }
}

function handleVerticalWidgetClicks(
    parent1Id,
    parent2Id,
    clickHandler,
    recommendationsModified,
    regExp
) {
    if (regExp.test(parent1Id)) {
        var parent1ArrayIndex = parent1Id.split("-")[3]; // fixed id of form *-vt-slider-0
        var parent2ArrayIndex = parent2Id.split("-")[3];
        clickHandler(
            recommendationsModified[parent2ArrayIndex][parent1ArrayIndex]
        );
    }
}
function getClosestNode(elem, selector) {
    var firstChar = selector.charAt(0);

    // Get closest match
    for (; elem && elem !== document; elem = elem.parentNode) {
        // If selector is a class
        if (firstChar === ".") {
            if (elem.classList.contains(selector.substr(1))) {
                return elem;
            }
        }

        // If selector is an ID
        if (firstChar === "#") {
            if (elem.id === selector.substr(1)) {
                return elem;
            }
        }

        // If selector is a data attribute
        if (firstChar === "[") {
            if (elem.hasAttribute(selector.substr(1, selector.length - 2))) {
                return elem;
            }
        }

        // If selector is a tag
        if (elem.tagName.toLowerCase() === selector) {
            return elem;
        }
    }

    return false;
}
function setBoutiqueInfo(item) {
    if (item.content && item.position && item.name) {
        const injectable = document.createElement("a");
        const papa = document.querySelector("body");
        updatePostion(item.position, injectable);
        injectable.href = "/personal-boutique";
        injectable.className = "boutique";
        papa.appendChild(injectable);
        injectable.innerHTML = item.content;
        injectable.firstChild.innerHTML = item.name;
    } else {
        console.log("boutique button not called");
    }
}

function updatePostion(position = "left", injectable) {
    const styles = styles_positon_outer[position];
    var cssArr = Object.keys(styles);
    for (var k = 0; k < cssArr.length; k++) {
        injectable.style[cssArr[k]] = styles[cssArr[k]];
    }
}

const styles_positon_outer = {
    right: {
        "position": "fixed",
        "z-index": 999,
        "right": "-70px",
        "top": "50%",
        "transform": "rotate(270deg) translateX(-50%)",
        "display": "flex",
        "text-decoration": "none",
    },
    left: {
        "position": "fixed",
        "z-index": 999,
        "left": "-70px",
        "top": "50%",
        "transform": "rotate(90deg) translateX(-50%)",
        "display": "flex",
        "text-decoration": "none",
    },
    bottom: {
        "position": "fixed",
        "z-index": 999,
        "bottom": "-1%",
        "left": "42%",
        "display": "flex",
        "text-decoration": "none",
    },
};

function updateAddonIconValue(addOnIcon, initialValue, UpdatedValue) {
    console.log(addOnIcon.getAttribute("data-unbxdparam_globaladdons_type"));
    if (
        addOnIcon.getAttribute("data-unbxdparam_globaladdons_type") ===
        initialValue
    ) {
        addOnIcon.setAttribute(
            "data-unbxdparam_globaladdons_type",
            UpdatedValue
        );
    } else {
        addOnIcon.setAttribute(
            "data-unbxdparam_globaladdons_type",
            initialValue
        );
    }
}
