export const cookieManager = {
    getDomain: function (hostname) {
        var bits = hostname.split(".").reverse();
        return bits.length >= 3 &&
            bits[1].match(/^(com|edu|gov|net|org|co|name|info|biz|myshopify)$/i)
            ? bits[2] + "." + bits[1] + "." + bits[0]
            : bits[1] + "." + bits[0];
    },
    setCookie: function (cname, cvalue, exSeconds) {
        var d = new Date();
        let expiry = exSeconds ? exSeconds * 1000 : 1 * 24 * 60 * 60 * 1000;
        d.setTime(d.getTime() + expiry);
        var expires = "expires=" + d.toUTCString();
        var cooked =
            cname +
            "=" +
            encodeURIComponent(btoa(cvalue)) +
            ";path=/;domain=." +
            cookieManager.getDomain(window.location.origin);
        document.cookie = expiry ? cooked + ";" + expires : cooked;
    },
    getCookie: function (cname) {
        var name = cname + "=",
            kuki = document.cookie;
        try {
            kuki = decodeURIComponent(kuki);
        } catch (e) {}
        var ca = kuki.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                var cv = c.substring(name.length, c.length);
                try {
                    cv = decodeURIComponent(cv);
                    return atob(cv);
                } catch (e) {}
                return cv;
            }
        }
        return "";
    },
};
