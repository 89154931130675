/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
    getDeviceType,
    getBrowserSize,
    MOBILE,
    SMALL,
    fetchData,
    injectCss,
} from "./utils";
import { eventHandlers, setImagesSource, setNavigationHtml, sendWarning } from "./handlers";

import { sliderConfig } from "./utils";
import { incrementCounter } from "./incrementCounter";
import { getRatingContent } from "./ratings";
import { strikeThrough } from "./strikeThrough";
import "./dot";
global._unbxd_recsSliderScrollNext = eventHandlers._unbxd_recsSliderScrollNext;
global._unbxd_recsSliderScrollPrev = eventHandlers._unbxd_recsSliderScrollPrev;
global._unbxd_recsSliderSideScroll = eventHandlers._unbxd_recsSliderSideScroll;
export const generateRexContent = (options) => {
    try {
        var template =
            options.template || missingValueError("template", options);
        let recommendations =
            options.recommendations ||
            missingValueError("recommendations", options);

        let selector =
            options.targetDOMElementId ||
            missingValueError("targetDOMElementId", options);
        let heading = options.heading;
        const showHeading = options.showHeading;
        let config =
            options.rexConsoleConfigs ||
            missingValueError("rexConsoleConfigs", options);
        let itemsToShow =
            config.products.visible ||
            missingValueError("products.visible", config);
        let maxProducts =
            config.products.max ||
            missingValueError("products.max", config.products);
        //need to passs -- globalAddonsAssets & orientation
        let orientation =
            options.orientation ||
            missingValueError("orientation", options.orientation);
        window.globalAddonsAssets = options.globalAddonsAssets;
        window.assets = options.assets;
        let compressedStyle = config.css || missingValueError("css", config);
        let recommendationsModified = null;
        let unbxdDeviceType =
            options.unbxdDeviceType ||
            missingValueError("orientation", options);
        let widgetWidthData = config.width ? config.width : null;
        if (!widgetWidthData) {
            widgetWidthData =
                config.widget.width ||
                missingValueError("products.widget.width", config.widget);
        }
        let widgetWidth = "";
        if (widgetWidthData.value && widgetWidthData.value != 0) {
            widgetWidth = widgetWidthData.value + widgetWidthData.unit;
        }
        let device = getDeviceType();
        let browserSize = getBrowserSize();
        if (maxProducts) {
            if (recommendations.length) {
                if (maxProducts < recommendations.length) {
                    recommendations = recommendations.splice(0, maxProducts);
                }
            }
        }
        let itemsDetail = productCount(
            config,
            unbxdDeviceType,
            browserSize,
            device
        );
        if (orientation == "vertical") {
            window._unbxd_recsItemToScrollVt = itemsDetail.itemsToShow;
        } else if (unbxdDeviceType === "mobile-browser") {
            window._unbxd_recsItemToScrollHz = itemsDetail.itemsToShow;
        } else {
            window._unbxd_recsItemToScrollHz = itemsDetail.itemsToShow;
        }
        let conf = {
            orientation,
            recommendations,
            itemsToShow,
            itemsDetail,
            config,
            template,
            selector,
            heading,
            showHeading,
        };
        if (recommendations) {
            var isTemplateRendered = renderTemplateData(conf);
        }
        if (isTemplateRendered) {
            let configType =
                config.custom_properties.widgetType === 3
                    ? config.custom_properties.basedOn
                        ? `${config.template_type}_${config.custom_properties.basedOn}`
                        : config.template_type
                    : orientation;
            if (
                config.custom_properties.widgetType === 3 &&
                configType === "boutique_tinder"
            ) {
                bxTin.init();
                injectCss(
                    ".unbxd_recs-boutique-tinder-container{height:480px}"
                );
            }
            let sliderContent = sliderConfig[configType];
            var domSelector = "#" + selector + " " + sliderContent.containerId;
            var sliderContainer = document.querySelector(domSelector);
            let sliderClass =
                orientation == "vertical" ||
                !window.unbxdDeviceType === "mobile-browser"
                    ? "_unbxd_recs-vertical-slider"
                    : "_unbxd_recs-slider";
            let sliderItemSelector =
                "#" + selector + " ." + sliderContent.sliderItemClassSelector;
            let sliderItems = document.querySelectorAll(sliderItemSelector);
            if (!sliderItems.length) {
                sendWarning(
                    "Found 0 nodes with class : " +
                        sliderContent.sliderItemClassSelector
                );
            }
            var productFields = conf.config.products.fields;
            productFields = productFields.sort(function (b, a) {
                a.sequence = a.sequence || a.sequence_number;
                b.sequence = b.sequence || b.sequence_number;
                if (a.sequence < b.sequence) {
                    return 1;
                }
                return -1;
            });
            addProductFields(
                config,
                sliderItems,
                productFields,
                recommendations,
                sliderContent,
                domSelector
            );
            var maxprodLimit = maxProducts;
            var recsSliderSelector = "#" + selector + " ." + sliderClass;
            var recsSlider = document.querySelector(recsSliderSelector);
            if (!recsSlider) {
                sendWarning("Slider Parent id was not found in the DOM");
            }
            if (recommendations.length < maxProducts) {
                maxprodLimit = recommendations.length;
            }
            let recommendationsModified = [];
            if (
                orientation == "vertical" &&
                config.template_type !== "boutique"
            ) {
                for (var i = 0; i < recommendations.length; i++) {
                    if (i % itemsToShow === 0) {
                        let a = i % itemsToShow;
                        var slicedItems = recommendations.slice(
                            i,
                            i + itemsToShow
                        );
                        recommendationsModified.push(slicedItems);
                    }
                }
            }
            let configuration = {
                selector,
                widgetWidth,
                itemsToShow,
                itemWidth: itemsDetail.itemWidth,
                config,
                recommendationsModified,
            };
            incrementCounter(
                sliderContent,
                configuration,
                sliderContainer,
                sliderItems,
                sliderClass,
                maxprodLimit
            );
            addCssAndHeadersForTemplates(
                configuration,
                sliderContent,
                recommendations
            );
        }
    } catch (e) {
        console.error("error in rendering template", e);
    }
};
function missingValueError(valueKey, contentObject) {
    throw new Error(
        "Error: " + valueKey + " not found in " + JSON.stringify(contentObject)
    );
}
function productCount(config, unbxdDeviceType, browserSize, device) {
    let itemsToShow =
        config.products && config.products.visible
            ? config.products.visible
            : 2;
    var itemsToShowOnMobile, itemWidth, itemWidthUnit;
    if (
        unbxdDeviceType === "mobile-browser" ||
        unbxdDeviceType === "mobile-browser" ||
        device === MOBILE ||
        browserSize === SMALL
    ) {
        itemWidth =
            (config.products.widget &&
                config.products.widget.width &&
                config.products.widget.width.value) ||
            0;
        itemWidthUnit =
            (config.products.widget &&
                config.products.widget.width &&
                config.products.widget.width.unit) ||
            "px";
        if (config && config.products && config.products.visibleOn) {
            itemsToShowOnMobile = config.products.visibleOnMobile;
        } else {
            itemsToShowOnMobile = config.products.visible;
        }
        itemsToShow = itemsToShowOnMobile ? itemsToShowOnMobile : 2;
    } else if (config && config.products) {
        if (config && config.products && config.products.visibleOn) {
            itemsToShow = config.products.visibleOn.desktop;
        }
        if (config.products.visible) {
            itemsToShow = config.products.visible;
        }
    } else {
        itemsToShow = itemsToShow ? itemsToShow : 2;
    }
    return { itemsToShow, itemWidthUnit, itemWidth };
}

function renderTemplateData(conf) {
    if (conf) {
        let recommendationsModified = [];
        if (
            conf.orientation == "vertical" &&
            conf.config.template_type !== "boutique"
        ) {
            for (var i = 0; i < conf.recommendations.length; i++) {
                if (i % conf.itemsToShow === 0) {
                    let a = i % conf.itemsToShow;
                    var slicedItems = conf.recommendations.slice(
                        i,
                        i + conf.itemsToShow
                    );
                    recommendationsModified.push(slicedItems);
                }
            }
        }
        var templateData = {
            recommendations:
                recommendationsModified.length > 0
                    ? recommendationsModified
                    : conf.recommendations,
            analyticsData: {
                widgetNum: conf.widgetPlacementId,
                pageType: window.unbxdPageType,
                requestId: window.reqId,
            },
            heading: conf.showHeading ? conf.heading : null,
            isGlobalAddonEnabled: conf.config.widget_addon,
            addOnData: {
                like: conf.config.addon_details.likeable,
                dislike: conf.config.addon_details.dislikeable,
                share: conf.config.addon_details.shareable,
                fave: conf.config.addon_details.wishlist,
            },
        };
        /* Callback to make any modification to data and pass on the modified data to renderFn  */
        if (window.dataParser && typeof window.dataParser === "function") {
            templateData = window.dataParser(templateData);
        }
        if (
            window.eventQueue &&
            typeof window.eventQueue["beforeTemplateRender"] === "function"
        ) {
            var beforeTemplateRenderCallback =
                window.eventQueue["beforeTemplateRender"];
            templateData = beforeTemplateRenderCallback(templateData);
        }
        var renderFn = doT.template(conf.template);
        var renderTargetEl = document.getElementById(conf.selector);
        if (renderTargetEl) {
            renderTargetEl.innerHTML = renderFn(templateData);
        }
        var eventHandlerStyle = document.createElement("style");
        eventHandlerStyle.type = "text/css";
        // innerHTML needs to stay as es5 since it will be embedded directly to client's browser
        eventHandlerStyle.innerHTML = conf.config.css;
        document.head.appendChild(eventHandlerStyle);
        if (conf.config.widget_addon) {
            addIconsForGlobalAddonButton(conf);
        }
    }
    return true;
}
function addIconsForGlobalAddonButton(conf) {
    let globalAddons = conf.config.addon_details;
    let style =
        conf.orientation !== "vertical" &&
        conf.config.template_type !== "boutique"
            ? ".unbxd--action--buttons>a{padding:4%;width:20%;}"
            : conf.config.template_type === "boutique" &&
              conf.config.custom_properties &&
              conf.config.custom_properties.basedOn === "instagram"
            ? ".unbxd--wishlist{left:75px}"
            : "";
    if (window.globalAddonsAssets) {
        if (
            globalAddons.likeable &&
            window.globalAddonsAssets.likebutton &&
            Object.keys(window.globalAddonsAssets.likebutton).length > 0
        ) {
            style += `.liked-img{content : url(${window.globalAddonsAssets.likebutton.selected_icon_src})}.like-img{content:url(${window.globalAddonsAssets.likebutton.unselected_icon_src})}`;
        }
        if (
            globalAddons.dislikeable &&
            Object.keys(window.globalAddonsAssets.dislikebutton).length > 0
        ) {
            style += `.disliked-img{content : url(${window.globalAddonsAssets.dislikebutton.selected_icon_src})}.dislike-img{content:url(${window.globalAddonsAssets.dislikebutton.unselected_icon_src})}`;
        }
        if (
            globalAddons.wishlist &&
            Object.keys(window.globalAddonsAssets.wishlistbutton).length > 0
        ) {
            style += `.wishlist-img{content : url(${window.globalAddonsAssets.wishlistbutton.unselected_icon_src})}.wishlisted-img{content:url(${window.globalAddonsAssets.wishlistbutton.selected_icon_src})}`;
        }
        if (
            globalAddons.shareable &&
            Object.keys(window.globalAddonsAssets.sharebutton).length > 0
        ) {
            style += `.share-img{content : url(${window.globalAddonsAssets.sharebutton.unselected_icon_src})}.shared-img{content:url(${window.globalAddonsAssets.sharebutton.selected_icon_src})}`;
        }
        injectCss(style);
    }
}
export function addProductFields(
    conf,
    sliderItems,
    productFields,
    recommendations,
    sliderContent,
    domSelector
) {
    for (var i = 0; i < sliderItems.length; i++) {
        var fragment = document.createDocumentFragment();
        for (var j = 0; j < productFields.length; j++) {
            var styles =
                productFields[j].styles ||
                missingValueError("styles", productFields[j]);
            var productAttributeKey =
                productFields[j].unbxdDimensionKey ||
                productFields[j].catalogKey ||
                missingValueError(
                    "unbxdDimensionKey or catalogKey",
                    productFields[j]
                );
            var cssArr = Object.keys(styles);
            if (!recommendations[i][productAttributeKey]) {
                productAttributeKey = productFields[j].catalogKey;
            }
            // appending fields to slider item
            // field appending doesn't applies to imageUrl
            if (productAttributeKey != "imageUrl") {
                var newnode = document.createElement("p");
                var dimension = recommendations[i][productAttributeKey];
                newnode.className = sliderContent.sliderContentClass;
                newnode.tabIndex = 0;
                newnode.setAttribute("role", "button");
                if (
                    conf &&
                    conf.products.strike_price_feature &&
                    productAttributeKey ==
                        conf.products.strike_price_feature.new.field
                ) {
                    if (conf && conf.products.strike_price_feature.enabled) {
                        newnode.innerHTML = strikeThrough(
                            recommendations[i],
                            conf,
                            domSelector
                        );
                    } else {
                        newnode.innerHTML = conf.products.currency + dimension;
                    }
                } else if (
                    conf &&
                    conf.products.ratings_feature &&
                    conf.products.ratings_feature.enabled &&
                    productFields[j].unbxdDimensionKey &&
                    productFields[j].unbxdDimensionKey.toLowerCase() == "rating"
                ) {
                    var ratingContentData = getRatingContent(
                        recommendations[i],
                        conf.products.ratings_feature,
                        domSelector,
                        productAttributeKey
                    );
                    if (ratingContentData) {
                        newnode.innerHTML = ratingContentData;
                    }
                } else {
                    if (!dimension) {
                        newnode.innerHTML = "";
                    } else {
                        newnode.innerHTML =
                            dimension instanceof Array
                                ? dimension.join(", ")
                                : dimension;
                    }
                }

                if (newnode.innerHTML) {
                    for (var k = 0; k < cssArr.length; k++) {
                        newnode.style[cssArr[k]] = styles[cssArr[k]];
                    }
                    fragment.appendChild(newnode);
                }
            }
        }
        let fig = sliderItems[i].querySelector("._unbxd_recs_product_details");
        fig.appendChild(fragment);
    }

    // Setting width of each slider item and
    // setting width of the visible slider
}
function addCssAndHeadersForTemplates(
    template,
    sliderContent,
    recommendations
) {
    if (recommendations.length <= template.itemsToShow) {
        var navigationButtonSelector =
            "#" + template.selector + " " + sliderContent.buttonClassSelector;
        var navigationButtons = document.querySelectorAll(
            navigationButtonSelector
        );
        if (!navigationButtons || !navigationButtons.length) {
            sendWarning(
                sliderContent.buttonClassSelector +
                    "class not found on navigation buttons"
            );
        }
        for (var i = 0; i < navigationButtons.length; i++) {
            navigationButtons[i].style.display = "none";
        }
    }
    // the previous button for the slider needs to be disabled initially
    var prevSliderButtonSelector =
        "#" + template.selector + " ." + sliderContent.prevButtonClass;
    var customNavButtonSelector = 
        "#" + template.selector + " ." + sliderContent.customNavClass;
    var prevSliderButton = document.querySelector(prevSliderButtonSelector);
    var customNavButton = document.querySelector(customNavButtonSelector);

    var navCustomisable = false;

    if(customNavButton) {
        navCustomisable = true;
    } else {
        navCustomisable = false
    }

    if (!prevSliderButton) {
        sendWarning(
            sliderContent.prevButtonClass +
                " class was not found on the navigation buttons"
        );
    }
    if (prevSliderButton) {
        prevSliderButton.disabled = true;
    }
    var headingSelector =
        "#" + template.selector + sliderContent.headingContainerId;
    var styleConfig = template.config.header;

    var headingEl = document.querySelector(headingSelector);
    if (headingEl.innerHTML == "null" || headingEl.innerHTML == "undefined") {
        headingEl.style.display = "none";
    } else {
        headingEl.style.textAlign = styleConfig.alignment;
        headingEl.style.fontSize =
            styleConfig.text.size.value + styleConfig.text.size.unit;
        headingEl.style.fontWeight = styleConfig.text.style;
        headingEl.style.color = styleConfig.text.colour;
    }
    /** Setting images value */
    var imgArr = [];
    var classMap = {
        next_arrow: sliderContent.nextButtonClass,
        prev_arrow: sliderContent.prevButtonClass,
        empty_rating: "_unbxd_rex-empty-star",
        half_rating: "_unbxd_rex-half-star",
        full_rating: "_unbxd_rex-full-star",
    };
    var altTextMap = {
        next_arrow: "Next Arrow",
        prev_arrow: "Previous Arrow",
        empty_rating: "Empty star",
        half_rating: "half star",
        full_rating: "full star",
    };
    for (let i = 0; i < window.assets.length; i++) {
        var horizontalAssetItem = window.assets[i];
        imgArr.push({
            classname: classMap[horizontalAssetItem.tag],
            altText: altTextMap[horizontalAssetItem.tag],
            url: horizontalAssetItem.src,
        });
    }
    setNavigationHtml(imgArr, template.selector, navCustomisable);
    setImagesSource(imgArr, template.selector, navCustomisable);

    /** Setting images value end*/
}
const bxTin = {
    animating: false,
    swipeDelta: 120,
    dragDx: 0,
    startX: 0,
    deg: 0,
    pcd: undefined,
    pcdReject: undefined,
    pcdLike: undefined,
    bod: document.querySelector("#widget-horizontal1"),
    startEve: ["mousedown"],
    moveEve: ["mousemove"],
    endEve: ["mouseup"],
    init: () => {
        window.bxTinderConf &&
            !objIsEmpty(window.bxTinderConf) &&
            Object.assign(bxTin, bxTinderConf);
        bxTin.startEve.forEach((ev) => {
            bxTin.bod.addEventListener(ev, function (e) {
                if (bxTin.animating || !e.target.closest(".unbxdtin")) return;
                bxTin.pcd = e.target.closest(".unbxdtin");
                bxTin.pcdReject = bxTin.pcd.querySelector(
                    ".unbxddislikeablebtn"
                );
                bxTin.pcdLike = bxTin.pcd.querySelector(".unbxdlikeablebtn");
                bxTin.startX =
                    e.pageX ||
                    e.targetTouches[0].pageX ||
                    e.originalEvent.touches[0].pageX;
                bxTin.moveEve.forEach((ev) => {
                    bxTin.bod.addEventListener(ev, bxTin.tinMv);
                });
                bxTin.endEve.forEach((ev) => {
                    bxTin.bod.addEventListener(ev, function tinEnd(e) {
                        bxTin.moveEve.forEach((eve) => {
                            bxTin.bod.removeEventListener(eve, bxTin.tinMv);
                        });
                        bxTin.bod.removeEventListener(ev, tinEnd);
                        if (!bxTin.dragDx) return;
                        bxTin.release();
                    });
                });
            });
        });
    },
    pullChange: () => {
        bxTin.animating = true;
        bxTin.deg = bxTin.dragDx / 10;
        bxTin.pcd.style.transform =
            "translateX(" + bxTin.dragDx + "px) rotate(" + bxTin.deg + "deg)";
        const opacity = bxTin.dragDx / 100;
        const rejectOpacity = opacity >= 0 ? 0 : Math.abs(opacity);
        const likeOpacity = opacity <= 0 ? 0 : opacity;
        bxTin.pcdReject.style.opacity = rejectOpacity;
        bxTin.pcdLike.style.opacity = likeOpacity;
    },
    release: () => {
        let eType = "liked";
        if (bxTin.dragDx >= bxTin.swipeDelta) {
            bxTin.pcd.classList.add("to-right");
        } else if (bxTin.dragDx <= -bxTin.swipeDelta) {
            bxTin.pcd.classList.add("to-left");
            eType = "disliked";
        }
        if (Math.abs(bxTin.dragDx) >= bxTin.swipeDelta) {
            bxTin.pcd.classList.add("inactive");
            //pxSwipe(bxTin.pcd, eType);
            bxTin.pcd.remove();
            // TODO >>> Fetch replacement
        }
        if (Math.abs(bxTin.dragDx) < bxTin.swipeDelta) {
            bxTin.pcd.classList.add("reset");
            setTimeout(() => {
                bxTin.pcd.classList.remove("reset");
                bxTin.pcd.setAttribute("style", "");
                bxTin.pcdLike.setAttribute("style", "");
                bxTin.pcdReject.setAttribute("style", "");
            }, 300);
        }
        bxTin.dragDx = 0;
        bxTin.animating = false;
    },
    tinMv: (e) => {
        const x =
            e.pageX ||
            e.originalEvent.touches[0].pageX ||
            e.targetTouches[0].pageX;
        bxTin.dragDx = x - bxTin.startX;
        if (!bxTin.dragDx) return;
        bxTin.pullChange();
    },
};
