import { sendWarning } from "./handlers";
export function incrementCounter(
    sliderContent,
    template,
    sliderContainer,
    sliderItems,
    sliderClass,
    maxprodLimit
) {
    try {
        if (sliderContent.dimension == "width") {
            setTimeout(function () {
                var sliderParentContainer = document.querySelector(
                    "#" + template.selector + " " + sliderContent.container
                );
                var sliderRootContainer = sliderParentContainer.parentElement;
                sliderParentContainer.style.width =
                    template.widgetWidth || "initial";
                if (
                    sliderRootContainer.clientWidth <
                    sliderParentContainer.clientWidth
                ) {
                    sliderParentContainer.style.width =
                        sliderRootContainer.clientWidth + "px";
                }
                sliderContainer.style.width =
                    sliderContainer[sliderContent.offsetDimension] + "px";
                let margin = 10;
                var hzSliderWidth =
                    (sliderContainer[sliderContent.offsetDimension] -
                        template.itemsToShow * margin) /
                    template.itemsToShow;
                var recsSliderSelector =
                    "#" + template.selector + " ." + sliderClass;
                var recsSlider = document.querySelector(recsSliderSelector);
                if (!recsSlider) {
                    return sendWarning(
                        "Slider Parent id was not found in the DOM"
                    );
                }
                if (template.itemWidth) {
                    if (template.itemWidthUnit === "%") {
                        var itemWidthPercentToPx =
                            template.widgetWidthData *
                            0.01 *
                            sliderContainer[sliderContent.offsetDimension];
                        for (let i = 0; i < sliderItems.length; i++) {
                            sliderItems[i].style.width =
                                itemWidthPercentToPx + "px";
                            recsSlider.style.width =
                                maxprodLimit * itemWidthPercentToPx +
                                maxprodLimit * margin +
                                "px";
                        }
                    } else {
                        for (let i = 0; i < sliderItems.length; i++) {
                            sliderItems[i].style.width = template.widgetWidth;
                            recsSlider.style.width =
                                maxprodLimit * template.itemWidth +
                                maxprodLimit * margin +
                                template.itemWidth;
                        }
                    }
                } else {
                    for (var i = 0; i < sliderItems.length; i++) {
                        sliderItems[i].style.width = hzSliderWidth + "px";
                        recsSlider.style.width =
                            maxprodLimit * hzSliderWidth +
                            maxprodLimit * margin +
                            "px";
                    }
                }
                var opaqueElSelector = document.querySelector(
                    "#" + template.selector + " ._unxbd_slider_hide"
                );
                opaqueElSelector.classList.remove("_unxbd_slider_hide");
            }, 0);
        } else {
            setTimeout(function () {
                var sliderParentContainer = document.querySelector(
                    "#" + template.selector + " ._unbxd_vertical-recs-slider"
                );
                let margin = 10;
                var sliderRootContainer = sliderParentContainer.parentElement;
                // if root container width is less than configuration width, then
                // the container inherits root container width
                sliderParentContainer.style.width =
                    template.widgetWidth || "initial";
                if (
                    sliderRootContainer.clientWidth <
                    sliderParentContainer.clientWidth
                ) {
                    sliderParentContainer.style.width =
                        sliderRootContainer.clientWidth + "px";
                }
                if (template.itemWidth) {
                    for (let i = 0; i < sliderItems.length; i++) {
                        sliderItems[i].style.width = template.itemWidth;
                    }
                } else {
                    for (let i = 0; i < sliderItems.length; i++) {
                        sliderItems[i].style.width =
                            sliderParentContainer.clientWidth -
                            2 * margin +
                            "px";
                    }
                }
                var recsSliderSelector =
                    "#" + template.selector + " ." + sliderClass;
                var recsSlider = document.querySelector(recsSliderSelector);
                if (!recsSlider) {
                    return sendWarning(
                        "Slider Parent id was not found in the DOM"
                    );
                }
                recsSlider.style.width =
                    sliderParentContainer.clientWidth *
                        template.recommendationsModified.length +
                    "px";
                var opaqueElSelector = document.querySelector(
                    "#" + template.selector + " ._unxbd_slider_hide"
                );
                opaqueElSelector.classList.remove("_unxbd_slider_hide");
            }, 0);
        }
    } catch (e) {
        console.log(e);
    }
}
