export const MOBILE = "mobile";
export const DESKTOP = "desktop";
export const SMALL = "small";
export const LARGE = "large";

export const getDeviceType = () => {
    console.log("screen.width: ", window.screen.width);
    console.log("screen.height: ", window.screen.height);
    const mediaQueryList = window.matchMedia("(orientation: portrait)");
    if (mediaQueryList.matches) {
        console.log("portrait mode");
        if (window.screen.width <= 667) {
            return MOBILE;
        } else {
            return DESKTOP;
        }
    } else {
        console.log("landscape mode");
        if (window.screen.height <= 667) {
            return MOBILE;
        } else {
            return DESKTOP;
        }
    }
};

export const getBrowserSize = () => {
    console.log("window.innerWidth: ", window.innerWidth);
    console.log("window.innerHeight: ", window.innerHeight);
    if (window.innerWidth <= 667) {
        return SMALL;
    } else {
        return LARGE;
    }
};

/**
 * Global declaration section
 */

/** Function for fetching api requests */
export const fetchData = (url, setHeader, cb) => {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (
            this.readyState == 4 &&
            (this.status == 200 || this.status == 204)
        ) {
            // Typical action to be performed when the document is ready:
            var requestId;
            // Get request id only during call of recommendation API
            if (setHeader) {
                requestId = this.getResponseHeader("x-request-id");
            }
            cb(null, xhttp.responseText, requestId);
        } else if (
            this.readyState == 4 &&
            (this.status != 200 || this.status != 204)
        ) {
            cb("Invalid network request: " + url);
        }
    };
    xhttp.onerror = function () {
        cb("Failed network request: " + url);
    };
    xhttp.open("GET", url, true);
    if (setHeader)
        xhttp.setRequestHeader("unbxd-device-type", window.unbxdDeviceType);
    xhttp.send();
};
export const injectCss = function (css) {
    const stl = document.createElement("style");
    stl.type = "text/css";
    stl.appendChild(document.createTextNode(css));
    document.head.appendChild(stl);
};

export const injectJs = function (js, src) {
    const scr = document.createElement("script");
    if (js && js !== "//" && js !== "/**/") {
        scr.type = "text/javascript";
        scr.text = "try{" + js + "}catch(e){console.log(e)}";
    } else if (src) {
        scr.src = src;
    }
    document.body.appendChild(scr);
};

export const sliderConfig = {
    horizontal: {
        containerId: " #_unbxd_recs-slider-container",
        sliderItemClassSelector: "_unbxd_recs-slider__item",
        dimension: "width",
        offsetDimension: "offsetWidth",
        buttonClassSelector: "._unbxd_recs-slider-btn",
        prevButtonClass: "_unbxd_rex-slider--prev",
        nextButtonClass: "_unbxd_rex-slider--next",
        headingContainerId: " #_unbxd_recs-slider-heading",
        sliderContentClass: "_unbxd_recs-slider__content",
        RegexExp: /hz-item/,
        container: ".unbxd-recs-slider",
        customNavClass: "_unbxd_rex_custom_nav"
    },
    vertical: {
        containerId: " #_unbxd_recs-vertical-slider-container",
        sliderItemClassSelector: "_unbxd_recs-vertical-slider__item",
        dimension: "height",
        offsetDimension: "offsetHeight",
        buttonClassSelector: "._unbxd_recs-vertical-slider-btn",
        prevButtonClass: "_unbxd_rex-vertical-slider--top",
        nextButtonClass: "_unbxd_rex-vertical-slider--bottom",
        headingContainerId: " #_unbxd_recs-vertical-slider-heading",
        sliderContentClass: "_unbxd_recs-vertical-slider__content",
        RegexExp: /[0-9]-vt-level2-/,
        container: "._unbxd_vertical-recs-slider",
        customNavClass: "_unbxd_rex_custom_nav"
    },
    boutique: {
        containerId: ".unbxd-recs-boutique-wrapper",
        sliderItemClassSelector: "_unbxd_recs-boutique__item",
        headingContainerId: " #_unbxd_boutique-heading",
        dimension: "width",
        sliderContentClass: "_unbxd_recs-boutique-item__content",
        RegexExp: /boutique-item/,
        container: ".unbxd-recs-boutique-wrapper",
        customNavClass: "_unbxd_rex_custom_nav"
    },
    boutique_standard: {
        containerId: ".unbxd-recs-boutique-wrapper",
        sliderItemClassSelector: "_unbxd_recs-boutique__item",
        headingContainerId: " #_unbxd_boutique-heading",
        dimension: "width",
        sliderContentClass: "_unbxd_recs-boutique-item__content",
        RegexExp: /boutique-item/,
        container: ".unbxd-recs-boutique-wrapper",
        customNavClass: "_unbxd_rex_custom_nav"
    },
    boutique_tinder: {
        containerId: ".recs-boutique-tinder-widget-wrapper",
        sliderItemClassSelector: "_unbxd_recs-boutique-tinder__item",
        headingContainerId: " #_unbxd_recs-boutique-tinder-heading",
        dimension: "width",
        sliderContentClass: "_unbxd_recs-boutique-item__content",
        RegexExp: /boutique-item/,
        container: ".recs-boutique-tinder-widget-wrapper",
        customNavClass: "_unbxd_rex_custom_nav"
    },
    boutique_instagram: {
        containerId: ".unbxd-recs-boutique-insta-widget-wrapper",
        sliderItemClassSelector: "_unbxd_recs-boutique__item",
        headingContainerId: " #_unbxd_recs-boutique-insta-heading",
        dimension: "width",
        sliderContentClass: "_unbxd_recs-boutique-item__content",
        RegexExp: /boutique-item/,
        container: ".unbxd-recs-boutique-insta-widget-wrapper",
        customNavClass: "_unbxd_rex_custom_nav"
    },
};
