export function getExtraParams() {
    try {
        let pageInfo = window.context.pageInfo;
        let params = "";
        switch (window.unbxdPageType.toLowerCase()) {
            case "product":
                if (pageInfo.productIds) {
                    params += getProductIdsAsUrlParams(pageInfo.productIds);
                }
                break;
            case "cart":
                if (pageInfo.productIds) {
                    params += getProductIdsAsUrlParams(pageInfo.productIds);
                }
                break;
            case "category":
                var categoryUrl = "";
                var catlevel1Name = pageInfo.catlevel1Name;
                var catlevel2Name = pageInfo.catlevel2Name;
                var catlevel3Name = pageInfo.catlevel3Name;
                var catlevel4Name = pageInfo.catlevel4Name;

                if (catlevel1Name) {
                    categoryUrl =
                        "&" +
                        getUrlEncodedParam("catlevel1Name", catlevel1Name);
                    if (catlevel2Name) {
                        categoryUrl +=
                            "&" +
                            getUrlEncodedParam("catlevel2Name", catlevel2Name);
                        if (catlevel3Name) {
                            categoryUrl +=
                                "&" +
                                getUrlEncodedParam(
                                    "catlevel3Name",
                                    catlevel3Name
                                );
                            if (catlevel4Name) {
                                categoryUrl +=
                                    "&" +
                                    getUrlEncodedParam(
                                        "catlevel4Name",
                                        catlevel4Name
                                    );
                            }
                        }
                    }
                }
                params += categoryUrl;
                break;
            case "home":
                break;
            case "boutique":
                break;
            default:
                throw new Error("Invalid page type: " + window.unbxdPageType);
        }
        return params;
    } catch (e) {
        console.error(
            "error at adding params for recommendation request pageType",
            e
        );
    }
}
function getProductIdsAsUrlParams(productIdsList) {
    var queryStringLocal = "";
    if (productIdsList instanceof Array) {
        productIdsList.forEach(function (item) {
            queryStringLocal += "&" + getUrlEncodedParam("id", item);
        });
    } else {
        queryStringLocal += "&" + getUrlEncodedParam("id", productIdsList);
    }
    return queryStringLocal;
}
function getUrlEncodedParam(paramName, paramValue) {
    return "" + paramName + "=" + encodeURIComponent(paramValue);
}
