export var appendImagesToClass = function(className,  imgUrl, altText, targetContainerId) {
    var elements = document.querySelectorAll("#"+targetContainerId+" ."+className);
    for(var i=0; i< elements.length; i++){
        var img = document.createElement('img');
        img.src = imgUrl;
        img.alt = altText;
        elements[i].appendChild(img);
    }
}

export var appendSpanHtml = function(className, targetContainerId) {
    var elements = document.querySelectorAll("#"+targetContainerId+" ."+className);
    for(var i=0; i< elements.length; i++){
        if(className === "_unbxd_rex-slider--next") {
            var span = document.createElement('span');
            span.classList.add('next-arrow');
            elements[i].appendChild(span);
        } 
        if (className === "_unbxd_rex-slider--prev"){
            var span = document.createElement('span');
            span.classList.add('prev-arrow');
            elements[i].appendChild(span);
        }
    }
}

export var setImagesSource = function(imgArr, targetContainerId, navCustomisable){
    let newImgArr = []
    if(navCustomisable) {
        newImgArr = imgArr.filter(e => (e.altText !== "Next Arrow" && e.altText !== "Previous Arrow"))
    } else {
        newImgArr = imgArr;
    }
    for(var i=0; i<newImgArr.length; i++){
        appendImagesToClass(newImgArr[i].classname, newImgArr[i].url, newImgArr[i].altText, targetContainerId);
    }
}

export var setNavigationHtml = function(spanArr, targetContainerId, navCustomisable) {
    if(navCustomisable) {
        for(var i=0; i<spanArr.length; i++){
            appendSpanHtml(spanArr[i].classname, targetContainerId);
        }
    } 
}
