/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
    getDeviceType,
    getBrowserSize,
    MOBILE,
    SMALL,
    fetchData,
    injectJs,
} from "./utils";
import { exitIntentInitator } from "./exitIntent";
import { injectCss } from "./utils";
import { getRatingContent } from "./ratings";
import { strikeThrough } from "./strikeThrough";
import "./dot";
export const processTemplateData = async (template) => {
    try {
        let templateconf = {};
        //parsing single templates one by one
        if (template && Object.keys(template).length > 0) {
            if (template.message) {
                console.error(
                    "Error in widget response ",
                    template.widgetId,
                    template.templateId,
                    template.message
                );
                return;
            }
            if (template.widgetId) {
                let wI = template.widgetId;
                let widgetElement = window.widgetsConfig.find(
                    (data) => {
                        return data.name == template.widgetPlacementId;
                    } //needs to be fixed widget1 data key
                );
                templateconf[wI] = new Object();
                templateconf[wI].widgetId = template.widgetId;
                templateconf[wI].widgetPlacementId = template.widgetPlacementId;
                templateconf[wI].widgetTitle = template.widgetTitle;
                if (!widgetElement) {
                    return console.error("No Element found for widgetID");
                }
                template = Object.assign(widgetElement, template);
                templateconf[wI].selector = template.selector;
                if (
                    template.recommendations &&
                    template.templateData &&
                    template.templateData.uuid
                ) {
                    let templateConfig = template.templateData;
                    templateconf[wI].orientation =
                        templateConfig.conf && templateConfig.conf.orientation
                            ? templateConfig.conf.orientation
                            : "";
                    //-- need to update this !--! somewat
                    //-- need to update this !--! and check this somewat
                    var maxProducts =
                        templateConfig.conf.products.max ||
                        templateConfig.conf.products.max_products ||
                        templateConfig.conf.max_recs;
                    templateconf[wI].maxProducts = maxProducts;
                    if (maxProducts) {
                        if (template.recommendations.length) {
                            if (maxProducts < template.recommendations.length) {
                                template.recommendations =
                                    template.recommendations.splice(
                                        0,
                                        maxProducts
                                    );
                            }
                        }
                    }
                    templateconf[wI].recommendations = template.recommendations;
                    if (template.templateData.scriptUrl) {
                        templateconf[wI].url = template.templateData.scriptUrl;
                        let templateStructure = await fetchingTemplateStructure(
                            template.templateData.scriptUrl
                        );
                        if (templateStructure) {
                            templateconf[wI].templateStructure =
                                templateStructure;
                        } else {
                            console.error(
                                "error in fetching template data",
                                templateconf[wI].templateId
                            );
                        }
                    }

                    if (
                        template &&
                        template.templateData &&
                        template.templateData.conf
                    ) {
                        let widgetWidthData =
                            template.templateData.conf.width ||
                            missingValueError(
                                "products.widget.width",
                                template.templateData.conf
                            );
                        var widgetWidth = "";
                        if (
                            widgetWidthData.value &&
                            widgetWidthData.value != 0
                        ) {
                            widgetWidth =
                                widgetWidthData.value + widgetWidthData.unit;
                        }
                        templateconf[wI].conf = template.templateData.conf;
                        templateconf[wI].styling = template.templateData.conf
                            .css
                            ? template.templateData.conf.css
                            : "";
                        templateconf[wI].script = template.templateData.conf.js
                            ? template.templateData.conf.js
                            : "";
                        templateconf[wI].custom_properties = template
                            .templateData.conf.custom_properties
                            ? template.templateData.conf.custom_properties
                            : "";
                        template.widgetWidth = widgetWidth;
                        templateconf[wI].widgetWidthData =
                            widgetWidthData.value;
                        templateconf[wI].widgetWidthUnit = widgetWidthData.unit;
                        templateconf[wI].widgetWidth = widgetWidth;
                        template.device = getDeviceType();
                        template.browserSize = getBrowserSize();
                        templateconf[wI].device = template.device;
                        templateconf[wI].browserSize = template.browserSize;
                        let itemData = productCount(template);

                        template = { ...template, ...itemData };
                        templateconf[wI] = { ...templateconf[wI], ...itemData };
                        templateconf[wI].itemsToShow = template.itemsToShow;
                        if (
                            template.orientation == "vertical" &&
                            template.templateType !== "boutique"
                        ) {
                            window._unbxd_recsItemToScrollVt =
                                template.itemsToShow;
                        } else if (
                            window.unbxdDeviceType === "mobile-browser"
                        ) {
                            window._unbxd_recsItemToScrollHz =
                                template.itemsToShow;
                        } else {
                            window._unbxd_recsItemToScrollHz =
                                template.itemsToShow;
                        }

                        let renderData = getWidgetType(
                            templateconf,
                            template.templateData.conf,
                            template.widgetId
                        );
                        templateconf[wI] = {
                            ...templateconf[wI],
                            ...renderData,
                        };
                        window.tc = { ...window.tc, ...templateconf };
                        //!--! when doing exit intent
                        if (
                            templateconf[wI].templateType == 2 ||
                            templateconf[wI].isPopup
                        ) {
                            exitIntentInitator(templateconf[wI]);
                        }
                        if (templateconf[wI].templateType == 3) {
                            bxTin.init();
                        }

                        renderTemplateData(templateconf[wI]);
                        return Promise.resolve(true);
                    }
                }
            }
        }
    } catch (error) {
        console.error("Error in parsing template Data", template, error);
    }
};
export const templatesInit = (context) => {
    context.widgets.forEach((widg) => {
        return destoryPreviousTemplates(widg);
    });
};

function destoryPreviousTemplates(widget) {
    document.getElementById(widget.selector).innerHTML = "";
}

function fetchingTemplateStructure(url) {
    try {
        return new Promise((resolve, reject) => {
            fetchData(url, false, function (err, res, reqId) {
                resolve(res);
            });
        });
    } catch (err) {
        console.error(err);
    }
}
function getWidgetType(templateconf, template, wI) {
    //!--! check this
    let widgetInfoData = {
        selector: templateconf[wI].selector,
        templateType:
            template.customProperties && template.customProperties.widgetType
                ? template.customProperties.widgetType
                : "",

        renderTemplate: 1,
    };
    if (
        template.widget_addon &&
        Object.keys(template.addonDetails).length > 0
    ) {
        widgetInfoData.globalAssets = 1;
        widgetInfoData.templateAddonData = {
            ...template.addonDetails,
        };
    }
    return widgetInfoData;
}
function productCount(template) {
    //!--! check this done
    let itemsToShow =
        template.templateData.conf.products &&
        template.templateData.conf.products.visible
            ? template.templateData.conf.products.visible
            : template.templateData.conf && template.templateData.conf.num_recs
            ? template.templateData.conf.num_recs
            : 2;
    var itemsToShowOnMobile, itemWidth, itemWidthUnit;
    if (
        window.unbxdDeviceType === "mobile-browser" ||
        template.unbxdDeviceType === "mobile-browser" ||
        template.unbxdDeviceType === MOBILE ||
        template.browserSize === SMALL
    ) {
        itemWidth =
            (template.templateData.conf &&
                template.templateData.conf.width &&
                template.templateData.conf.width.value) ||
            0;
        itemWidthUnit =
            (template.templateData.conf &&
                template.templateData.conf.width &&
                template.templateData.conf.width.unit) ||
            "px";
        if (
            template.templateData.conf &&
            template.templateData.conf.products &&
            template.templateData.conf.products.visible
        ) {
            itemsToShowOnMobile = template.templateData.conf.products.visible;
        } else {
            itemsToShowOnMobile =
                template.templateData.conf.products.visibleOn.mobile;
        }
        itemsToShow = itemsToShowOnMobile ? itemsToShowOnMobile : "2";
    } else {
        if (
            template.templateData.conf &&
            template.templateData.conf.products &&
            template.templateData.conf.products.visible
        ) {
            itemsToShow = template.templateData.conf.products.visible;
        } else if (template.templateData.conf.products.visibleOn) {
            itemsToShow = template.templateData.conf.products.visibleOn.desktop;
        } else {
            itemsToShow = template.templateData.conf.numRecs;
        }
        itemsToShow = itemsToShow ? itemsToShow : 2;
    }
    return { itemsToShow, itemWidthUnit, itemWidth };
}
function renderTemplateData(conf) {
    if (conf) {
        let recommendationsModified = [];
        if (
            conf.orientation == "vertical" &&
            conf.conf.templateType !== "boutique"
        ) {
            for (var i = 0; i < conf.recommendations.length; i++) {
                if (i % conf.itemsToShow === 0) {
                    let a = i % conf.itemsToShow;
                    var slicedItems = conf.recommendations.slice(
                        i,
                        i + conf.itemsToShow
                    );
                    recommendationsModified.push(slicedItems);
                }
            }
        }
        if (recommendationsModified) {
            window.tc[conf.widgetId].recommendationsModified =
                recommendationsModified;
        }
        var templateData = {
            recommendations:
                recommendationsModified.length > 0
                    ? recommendationsModified
                    : conf.recommendations,
            heading: conf.widgetTitle,
            analyticsData: {
                widgetNum: conf.widgetPlacementId.toUpperCase(),
                pageType: window.unbxdPageType,
                requestId: window.reqId,
            },
            isGlobalAddonEnabled: conf.conf.widgetAddon,
            addOnData: {
                like: conf.conf.addonDetails.likeable,
                dislike: conf.conf.addonDetails.dislikeable,
                share: conf.conf.addonDetails.shareable,
                fave: conf.conf.addonDetails.wishlist,
            },
        };
        /* Callback to make any modification to data and pass on the modified data to renderFn  */
        if (window.dataParser && typeof window.dataParser === "function") {
            templateData = window.dataParser(templateData);
        }
        if (
            window.eventQueue &&
            typeof window.eventQueue["beforeTemplateRender"] === "function"
        ) {
            var beforeTemplateRenderCallback =
                window.eventQueue["beforeTemplateRender"];
            templateData = beforeTemplateRenderCallback(templateData);
        }
        var renderFn = doT.template(conf.templateStructure);
        var renderTargetEl = document.getElementById(conf.selector);
        if (renderTargetEl) {
            renderTargetEl.innerHTML = renderFn(templateData);
        }
        var eventHandlerStyle = document.createElement("style");
        eventHandlerStyle.type = "text/css";
        // innerHTML needs to stay as es5 since it will be embedded directly to client's browser
        eventHandlerStyle.innerHTML = conf.styling;
        document.head.appendChild(eventHandlerStyle);
        if (conf.script) {
            injectJs(conf.script);
        }
        if (conf.conf.widgetAddon) {
            addIconsForGlobalAddonButton(conf);
        }
    }
}
export function addProductFields(
    template,
    sliderItems,
    productFields,
    recommendations,
    sliderContent,
    domSelector
) {
    for (var i = 0; i < sliderItems.length; i++) {
        var fragment = document.createDocumentFragment();
        for (var j = 0; j < productFields.length; j++) {
            var styles =
                productFields[j].styles ||
                missingValueError("styles", productFields[j]);
            var productAttributeKey =
                productFields[j].unbxdDimensionKey ||
                productFields[j].catalogKey ||
                productFields[j].unbxd_dimension_key ||
                missingValueError(
                    "unbxdDimensionKey or catalogKey",
                    productFields[j]
                );
            var cssArr = Object.keys(styles);
            if (
                recommendations[i] &&
                !recommendations[i][productAttributeKey]
            ) {
                productAttributeKey = productFields[j].catalogKey;
            }
            // appending fields to slider item
            // field appending doesn't applies to imageUrl
            if (productAttributeKey != "imageUrl" && recommendations[i]) {
                var newnode = document.createElement("p");
                var dimension = recommendations[i][productAttributeKey];
                newnode.className = sliderContent.sliderContentClass;
                newnode.tabIndex = 0;
                newnode.setAttribute("role", "button");
                if (
                    template &&
                    template.conf &&
                    template.conf.products.strikePriceFeature &&
                    productAttributeKey ==
                        template.conf.products.strikePriceFeature.new.field
                ) {
                    if (
                        template &&
                        template.conf &&
                        template.conf.products.strikePriceFeature.enabled
                    ) {
                        newnode.innerHTML = strikeThrough(
                            recommendations[i],
                            template.conf,
                            domSelector
                        );
                    } else {
                        newnode.innerHTML =
                            template.conf.products.currency + dimension;
                    }
                } else if (
                    template &&
                    template.conf &&
                    template.conf.products.ratingsFeature &&
                    template.conf.products.ratingsFeature.enabled &&
                    productFields[j].unbxdDimensionKey &&
                    productFields[j].unbxdDimensionKey.toLowerCase() == "rating"
                ) {
                    var ratingContentData = getRatingContent(
                        recommendations[i],
                        template.conf.products.ratingsFeature,
                        domSelector,
                        productAttributeKey
                    );
                    if (ratingContentData) {
                        newnode.innerHTML = ratingContentData;
                    }
                } else {
                    if (!dimension) {
                        newnode.innerHTML = "";
                    } else {
                        newnode.innerHTML =
                            dimension instanceof Array
                                ? dimension.join(", ")
                                : dimension;
                    }
                }
                if (newnode.innerHTML) {
                    for (var k = 0; k < cssArr.length; k++) {
                        newnode.style[cssArr[k]] = styles[cssArr[k]];
                    }
                    fragment.appendChild(newnode);
                }
            }
        }
        let fig = sliderItems[i].querySelector("._unbxd_recs_product_details");
        fig.appendChild(fragment);
    }

    // Setting width of each slider item and
    // setting width of the visible slider
}
function addIconsForGlobalAddonButton(conf) {
    let globalAddons = conf.conf.addonDetails;
    let style = "";
    if (window.globalAddonsAssets) {
        if (
            globalAddons.likeable &&
            Object.keys(window.globalAddonsAssets.likeButton).length > 0
        ) {
            style += `.liked-img{content : url(${window.globalAddonsAssets.likeButton.selectedIconSrc})}.like-img{content:url(${window.globalAddonsAssets.likeButton.unselectedIconSrc})}`;
        }
        if (
            globalAddons.dislikeable &&
            Object.keys(window.globalAddonsAssets.dislikeButton).length > 0
        ) {
            style += `.disliked-img{content : url(${window.globalAddonsAssets.dislikeButton.selectedIconSrc})}.dislike-img{content:url(${window.globalAddonsAssets.dislikeButton.unselectedIconSrc})}`;
        }
        if (
            globalAddons.wishlist &&
            Object.keys(window.globalAddonsAssets.wishlistButton).length > 0
        ) {
            style += `.wishlist-img{content : url(${window.globalAddonsAssets.wishlistButton.unselectedIconSrc})}.wishlisted-img{content:url(${window.globalAddonsAssets.wishlistButton.selectedIconSrc})}`;
        }
        if (
            globalAddons.shareable &&
            Object.keys(window.globalAddonsAssets.shareButton).length > 0
        ) {
            style += `.share-img{content : url(${window.globalAddonsAssets.shareButton.unselectedIconSrc})}.shared-img{content:url(${window.globalAddonsAssets.shareButton.selectedIconSrc})}`;
        }
        injectCss(style);
    } else {
        console.error("global addons assest is not defined");
    }
}
const bxTin = {
    animating: false,
    swipeDelta: 120,
    dragDx: 0,
    startX: 0,
    deg: 0,
    pcd: undefined,
    pcdReject: undefined,
    pcdLike: undefined,
    bod: document.querySelector("body"),
    startEve: ["touchstart"],
    moveEve: ["touchmove"],
    endEve: ["touchend"],
    init: () => {
        window.bxTinderConf &&
            !objIsEmpty(window.bxTinderConf) &&
            Object.assign(bxTin, bxTinderConf);
        bxTin.startEve.forEach((ev) => {
            bxTin.bod.addEventListener(ev, function (e) {
                if (bxTin.animating || !e.target.closest(".unbxdtin")) return;
                bxTin.pcd = e.target.closest(".unbxdtin");
                bxTin.pcdReject = bxTin.pcd.querySelector(
                    ".unbxddislikeablebtn"
                );
                bxTin.pcdLike = bxTin.pcd.querySelector(".unbxdlikeablebtn");
                bxTin.startX =
                    e.pageX ||
                    e.targetTouches[0].pageX ||
                    e.originalEvent.touches[0].pageX;
                bxTin.moveEve.forEach((ev) => {
                    bxTin.bod.addEventListener(ev, bxTin.tinMv);
                });
                bxTin.endEve.forEach((ev) => {
                    bxTin.bod.addEventListener(ev, function tinEnd(e) {
                        bxTin.moveEve.forEach((eve) => {
                            bxTin.bod.removeEventListener(eve, bxTin.tinMv);
                        });
                        bxTin.bod.removeEventListener(ev, tinEnd);
                        if (!bxTin.dragDx) return;
                        bxTin.release();
                    });
                });
            });
        });
    },
    pullChange: () => {
        bxTin.animating = true;
        bxTin.deg = bxTin.dragDx / 10;
        bxTin.pcd.style.transform =
            "translateX(" + bxTin.dragDx + "px) rotate(" + bxTin.deg + "deg)";
        const opacity = bxTin.dragDx / 100;
        const rejectOpacity = opacity >= 0 ? 0 : Math.abs(opacity);
        const likeOpacity = opacity <= 0 ? 0 : opacity;
        bxTin.pcdReject.style.opacity = rejectOpacity;
        bxTin.pcdLike.style.opacity = likeOpacity;
    },
    release: () => {
        let eType = "liked";
        if (bxTin.dragDx >= bxTin.swipeDelta) {
            bxTin.pcd.classList.add("to-right");
        } else if (bxTin.dragDx <= -bxTin.swipeDelta) {
            bxTin.pcd.classList.add("to-left");
            eType = "disliked";
        }
        if (Math.abs(bxTin.dragDx) >= bxTin.swipeDelta) {
            bxTin.pcd.classList.add("inactive");
            //pxSwipe(bxTin.pcd, eType);
            bxTin.pcd.remove();
            // TODO >>> Fetch replacement
        }
        if (Math.abs(bxTin.dragDx) < bxTin.swipeDelta) {
            bxTin.pcd.classList.add("reset");
            setTimeout(() => {
                bxTin.pcd.classList.remove("reset");
                bxTin.pcd.setAttribute("style", "");
                bxTin.pcdLike.setAttribute("style", "");
                bxTin.pcdReject.setAttribute("style", "");
            }, 300);
        }
        bxTin.dragDx = 0;
        bxTin.animating = false;
    },
    tinMv: (e) => {
        const x =
            e.pageX ||
            e.targetTouches[0].pageX ||
            e.originalEvent.touches[0].pageX;
        bxTin.dragDx = x - bxTin.startX;
        if (!bxTin.dragDx) return;
        bxTin.pullChange();
    },
};
