/* eslint-disable no-unused-vars */
import { injectCss } from "./utils";
import { cookieManager } from "./handlers/cookie";
export const exitIntentInitator = function (widgetConf) {
    if (
        widgetConf &&
        widgetConf.conf &&
        widgetConf.conf.customProperties &&
        widgetConf.conf.customProperties.widgetCondition
    ) {
        let widgetConfig = widgetConf.conf;
        const widgId =
            widgetConfig.customProperties.widgetCondition.Listenon +
            "-" +
            widgetConfig.customProperties.widgetCondition.event +
            "-modal";
        injectExitIntentModalStyles();
        const modalContainerPapa = document.createElement("div");
        modalContainerPapa.id = widgId + "-parent";
        modalContainerPapa.classList.add("unbxd-intent-modal-parent");
        document.body.appendChild(modalContainerPapa);
        modalContainerPapa.innerHTML =
            '<div class="modalContainer" id="' + widgId + '"></div>';
        widgetConf.selector = widgId;
        widgetConf.inject = "inner";
        attachIntentListener(widgetConf, widgetConfig);
        let wI = widgetConf.widgetId;
        let popupData = {};
        window.tc[wI].selector = widgetConf.selector;
        window.tc[wI].inject = widgetConf.inject;
        if (window.tc[wI].templateType == 2) {
            window.tc[wI].canPopUp = 1;
        }
    } else {
        console.warn("Widget inject selector found None or duplicates");
    }
};

const attachIntentListener = function (popup, widgetConfig) {
    // TODO, make a listenerKiller func
    const evTarget =
        widgetConfig.customProperties.widgetCondition &&
        widgetConfig.customProperties.widgetCondition.Listenon === "window"
            ? window
            : document.querySelector(
                  widgetConfig.customProperties.widgetCondition.Listenon
              );
    const modalId =
        widgetConfig.customProperties.widgetCondition.Listenon +
        "-" +
        widgetConfig.customProperties.widgetCondition.event +
        "-modal-parent";
    evTarget.addEventListener(
        widgetConfig.customProperties.widgetCondition.event,
        function intentListenr(e) {
            const modalId =
                widgetConfig.customProperties.widgetCondition.Listenon +
                "-" +
                widgetConfig.customProperties.widgetCondition.event +
                "-modal";
            if (document.getElementById(modalId)) {
                console.info(
                    "Popup intent detected " + modalId,
                    window.tc[popup.widgetId]
                );
                if (
                    (window.tc[popup.widgetId].canPopUp ||
                        window.tc[popup.widgetId].templateType == 2) &&
                    eval(
                        widgetConfig.customProperties.widgetCondition.callback
                    ) &&
                    !cookieManager.getCookie(modalId)
                ) {
                    document.getElementById(modalId + "-parent").style.display =
                        "table";
                    document.getElementById(
                        modalId + "-parent"
                    ).style.opacity = 1;
                    document.getElementById(
                        modalId + "-parent"
                    ).style.zIndex = 99;
                    let expiryTime = widgetConfig.customProperties
                        .widgetCondition.expireTime
                        ? widgetConfig.customProperties.widgetCondition
                              .expireTime
                        : false;
                    cookieManager.setCookie(modalId, "true", expiryTime);
                    console.info("Popup triggered successfully " + modalId);
                    document
                        .querySelector(
                            widgetConfig.customProperties.widgetCondition
                                .Listenon
                        )
                        .removeEventListener(
                            widgetConfig.customProperties.widgetCondition.event,
                            intentListenr
                        );
                } else {
                    console.info("Popup checks failed " + modalId);
                }
            } else {
                document
                    .querySelector(
                        widgetConfig.customProperties.widgetCondition.Listenon
                    )
                    .removeEventListener(
                        widgetConfig.customProperties.widgetCondition.event,
                        intentListenr
                    );
            }
        }
    );
};

const injectExitIntentModalStyles = function () {
    let css =
        ".unbxd-intent-modal-parent{display:table;opacity:0;position:fixed;top:0;left:0;z-index:-1;width:100%;height:100%;background:#777777b8;text-align:center}";
    css += ".modalContainer{display:table-cell;vertical-align:middle}";
    injectCss(css);
};
